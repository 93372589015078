.network-ui-panel {
  height: 100%;
  width: 100%;
  position: relative;
}
.network-ui-panel .content {
  height: 100%;
  width: 100%;
  padding: 36px 10% 10px 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  overflow: auto;
}
.network-ui-panel .btn-search {
  background: #374151;
  color: white;
  height: 40px;
  margin-top: 32px;
}
.network-ui-panel .collapse-card {
  width: 100%;
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  background: #fff;
}
.network-ui-panel .card-view {
  height: 100%;
  padding: 16px 16px 28px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  background: #fff;
  color: #312b2b;
  font-size: 14px;
}
.network-ui-panel .usecase-dropdown-menu {
  width: calc(100% - 24px);
  position: absolute;
  z-index: 10;
  padding: 3px 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: white;
}
.network-ui-panel .usecase-dropdown-menu li {
  padding: 3px 10px;
}
.network-ui-panel .usecase-dropdown-menu li.hover {
  background-color: #f3f4f6;
}

.network-ui-panel .typing-text {
  display: none;
  overflow: hidden;
  white-space: nowrap;
  animation: typeEffect 2s steps(24) forwards;
}

@keyframes typeEffect {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.network-ui-panel .input-box {
  display: none;
  transition: all 1s ease-in-out;
}

.network-ui-panel #icon-cursor {
  display: none;
  z-index: 20;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: moveTopLeft 1s linear forwards;
}
@keyframes moveTopLeft {
  0% {
    bottom: 0;
    transform: translateX(0);
  }
  100% {
    bottom: 420px;
    transform: translateX(-300px);
  }
}

.network-ui-panel #icon-cursor.move-to-dropdown-box {
  animation: moveTopRight 1s linear forwards;
}
@keyframes moveTopRight {
  0% {
    bottom: 0;
    transform: translateX(0);
  }
  100% {
    bottom: 420px;
    transform: translateX(300px);
  }
}

.network-ui-panel #icon-cursor.select-item {
  animation: moveToSelectItem 0.5s linear forwards;
}
@keyframes moveToSelectItem {
  0% {
    bottom: 420px;
    transform: translateX(300px);
  }
  100% {
    bottom: 380px;
    transform: translateX(300px);
  }
}

.network-ui-panel #icon-cursor.move-to-search-button {
  animation: modeToSearchButton 1s linear forwards;
}
@keyframes modeToSearchButton {
  0% {
    bottom: 380px;
    transform: translateX(300px);
  }
  100% {
    bottom: 340px;
    transform: translateX(-50%);
  }
}

.network-ui-panel #icon-cursor.move-to-network-api-tab {
  animation: moveToNetworkAPITab 1s linear forwards;
}
@keyframes moveToNetworkAPITab {
  0% {
    bottom: 340px;
  }
  100% {
    bottom: 750px;
    transform: translateX(150px);
  }
}

@media (max-width: 1023px) {
}

@media (max-width: 767px) {
  .network-ui-panel .tab-panel-main {
    width: 100%;
  }

  .network-ui-panel #icon-cursor {
    transform: translateX(0);
    animation: moveTopSearchTarget 1s linear forwards;
  }
  @keyframes moveTopSearchTarget {
    0% {
      bottom: 0;
    }
    100% {
      bottom: 420px;
    }
  }

  .network-ui-panel #icon-cursor.move-to-dropdown-box {
    animation: moveTopDropdownBox 1s linear forwards;
  }
  @keyframes moveTopDropdownBox {
    0% {
      bottom: 0;
    }
    100% {
      bottom: 340px;
    }
  }

  .network-ui-panel #icon-cursor.select-item {
    animation: moveToSelectItem 0.5s linear forwards;
  }
  @keyframes moveToSelectItem {
    0% {
      bottom: 340px;
    }
    100% {
      bottom: 300px;
    }
  }

  .network-ui-panel #icon-cursor.move-to-search-button {
    animation: modeToSearchButton 1s linear forwards;
  }
  @keyframes modeToSearchButton {
    0% {
      bottom: 300px;
    }
    100% {
      bottom: 260px;
    }
  }

  .network-ui-panel #icon-cursor.move-to-network-api-tab {
    animation: moveToNetworkAPITab 1s linear forwards;
  }
  @keyframes moveToNetworkAPITab {
    0% {
      bottom: 260px;
    }
    100% {
      bottom: 750px;
      transform: translateX(100px);
    }
  }
}
