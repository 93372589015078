.network-api-panel  {
  height: 100%;
  width: 100%;
  position: relative;
}

.network-api-panel .content  {
  height: 100%;
  width: 100%;
  padding: 36px 10% 10px 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  overflow-y: auto;
  margin: 0 auto;
}

.network-api-panel .btn-search {
  background: #374151;
  color: white;
  height: 40px;
}
.network-api-panel .usecase-dropdown-menu {
  width: calc(100% - 24px);
  position: absolute;
  z-index: 10;
  padding: 3px 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: white;
}
.network-api-panel .usecase-dropdown-menu li {
  padding: 3px 10px;
}
.network-api-panel .usecase-dropdown-menu li.hover {
  background-color: #f3f4f6;
}

.network-api-panel #icon-cursor {
  z-index: 20;
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: moveTopLeft 1s linear forwards;
}
@keyframes moveTopLeft {
  0% {
    bottom: 0;
    transform: translateX(0);
  }
  100% {
    bottom: 420px;
    transform: translateX(-300px);
  }
}

.network-api-panel #icon-cursor.move-to-dropdown-box {
  animation: moveTopRight 1s linear forwards;
}
@keyframes moveTopRight {
  0% {
    bottom: 0;
    transform: translateX(0);
  }
  100% {
    bottom: 420px;
    transform: translateX(300px);
  }
}

.network-api-panel #icon-cursor.select-item {
  animation: moveToSelectItem 0.5s linear forwards;
}
@keyframes moveToSelectItem {
  0% {
    bottom: 420px;
    transform: translateX(300px);
  }
  100% {
    bottom: 380px;
    transform: translateX(300px);
  }
}

.network-api-panel #icon-cursor.move-to-search-button {
  animation: modeToSearchButton 1s linear forwards;
}
@keyframes modeToSearchButton {
  0% {
    bottom: 380px;
    transform: translateX(300px);
  }
  100% {
    bottom: 340px;
    transform: translateX(-50%);
  }
}

.network-api-panel #icon-cursor.move-to-usual-process-tab {
  animation: modeToUsualProcessTab 1s linear forwards;
}
@keyframes modeToUsualProcessTab {
  0% {
    bottom: 340px;
  }
  100% {
    bottom: 750px;
    transform: translateX(-180px);
  }
}

.network-api-panel .typing-text {
  display: none;
  overflow: hidden;
  white-space: nowrap;
  animation: typeEffect 2s steps(24) forwards;
}

@keyframes typeEffect {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.network-api-panel .input-box {
  display: none;
  transition: all 1s ease-in-out;
}

@media (max-width: 767px) {
  .network-api-panel .tab-panel-main {
    width: 100%;
  }

  .network-api-panel #icon-cursor {
    transform: translateX(0);
    animation: moveTopSearchTarget 1s linear forwards;
  }
  @keyframes moveTopSearchTarget {
    0% {
      bottom: 0;
    }
    100% {
      bottom: 420px;
    }
  }

  .network-api-panel #icon-cursor.move-to-dropdown-box {
    animation: moveTopDropdownBox 1s linear forwards;
  }
  @keyframes moveTopDropdownBox {
    0% {
      bottom: 0;
    }
    100% {
      bottom: 340px;
    }
  }

  .network-api-panel #icon-cursor.select-item {
    animation: moveToSelectItem 0.5s linear forwards;
  }
  @keyframes moveToSelectItem {
    0% {
      bottom: 340px;
    }
    100% {
      bottom: 300px;
    }
  }

  .network-api-panel #icon-cursor.move-to-search-button {
    animation: modeToSearchButton 1s linear forwards;
  }
  @keyframes modeToSearchButton {
    0% {
      bottom: 300px;
    }
    100% {
      bottom: 260px;
    }
  }

  .network-api-panel #icon-cursor.move-to-usual-process-tab {
    animation: modeToUsualProcessTab 1s linear forwards;
  }
  @keyframes modeToUsualProcessTab {
    0% {
      bottom: 260px;
    }
    100% {
      bottom: 750px;
      transform: translateX(-130px);
    }
  }
}