header {
  padding: 14px 112px;
  display: flex;
  align-items: center;
}


header nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  margin-left: 48px;
}

header nav ul li  {
  padding: 6px 12px;
  color: #111827;
  font-size: 14px;
  cursor: pointer;
}
.menu-collapse{
    padding: 10px 0;
    position: absolute;
    max-width: 90%;
    width: 90%;
    left: 50% !important;
    top: 70px !important;
    transform: translateX(-50%);
    transform: translateX(-50%);
    min-width: 56px;
    transform-origin: left top;
    z-index: 10;
    background-color: white;
    border-radius: 2px;
    overflow-y: auto;
    overflow-x: hidden;
    contain: content;
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
  background: white;
  z-index: 20;
}

@media (max-width: 1023px) {
    header {
        padding: 14px 24px;
    }
}
