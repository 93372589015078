@import url('https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@400;500;600;700&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import 'bootstrap/dist/css/bootstrap.min.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


body {
  font-family: 'Roboto', sans-serif;
  color: #030712;
}

code {
  font-family: 'Roboto', sans-serif;
}

button {
  width: fit-content;
  color: #111827;
  border-radius: 10px !important;
  border: 1px solid #e5e7eb;
  padding: 10px 16px;
  font-size: 14px;
}
button.black {
  background-color: #111827;
  color: white;
  border: none;
  
}
button.black svg path {
  stroke: #6bc78a;
}
button.gray {
  background-color: #E5E7EB;
  color: #020617;
}
h1 {
  font-size: 40px;
  text-transform: none;
}
h2 {
  font-size: 36px;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}
.card-view{
  border-radius: 20px;
  border: 1px solid #E5E7EB;
  background: #FFF;
}
.card-view.black{
  border-radius: 16px;
  border: 1px solid #E5E7EB;
  background: #020617;
}
.logo {
  height: 40px;
  width: 194px;
  color: #606060;
}
.badge{
  height: fit-content;
  padding: 4px 16px;
  font-size: 14px;
  font-weight: 500;
  color: white

}
.CircularProgressbar-text{
  dominant-baseline: hanging !important;
}

.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}
.range-slider .range-slider__thumb, .range-slider .range-slider__range {
  background: #6BC78A !important;
}

.collapse{
  visibility: inherit;
}