.usual-procress-panel {
  height: 100%;
  width: 100%;
  position: relative;
}
.usual-procress-panel .search-content {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.usual-procress-panel #icon-cursor {
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  animation: moveUpAndHide 1s linear forwards;
}
@keyframes moveUpAndHide {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 350px;
  }
}

.usual-procress-panel #icon-cursor.search {
  animation: moveUpSearch 1s linear forwards;
}
@keyframes moveUpSearch {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 280px;
  }
}

.usual-procress-panel #icon-cursor.move-to-network-ui-tab {
  animation: moveToNetworkUITab 1s linear forwards;
}
@keyframes moveToNetworkUITab {
  0% {
    bottom: 280px;
  }
  100% {
    bottom: 750px;
  }
}

.usual-procress-panel .typing-text {
  display: none;
  overflow: hidden;
  white-space: nowrap;
  animation: typeEffect 2s steps(24) forwards;
}

@keyframes typeEffect {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.usual-procress-panel .input-box {
  display: none;
  transition: all 1s ease-in-out;
}
