footer{
    background: #020617;
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: center;
    background-image: url("../../../assets/img/img-background-footer.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 90%;
}
footer .logo{
    color: white;
}