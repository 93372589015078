section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 112px;
  padding-right: 112px;
}

/* ---------------------------------------------------- section-hero ---------------------------------------------------- */
#section-hero .background-image {
  width: 100%;
  background-image: url("../../assets/img/img-background-ellipse2.png"),
    url("../../assets/img/img-background-ellipse2.png");
  background-size: auto 70%, 50% 50%;
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, right top;
}

#section-hero .tab-panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-image: url("../../assets/img/img-background-hero-section.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100px;
  padding: 40px 82px;
}

#section-hero .tab-panel {
  background-color: white;
  width: 100%;
  margin-bottom: 60px;
  box-shadow: 0px 10px 10px -5px rgba(4, 9, 25, 0.04),
    0px 12px 20px -4px rgba(4, 9, 25, 0.1);
}
#section-hero .tab-panel-header {
  border-bottom: 1px solid #d1d5db;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

#section-hero .tab-panel-header .search-bar {
  width: 50%;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #f3f4f6;
  padding: 4px 0;
  margin: auto;
}

#section-hero .tab-panel-main {
  position: relative;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 120px;
  padding-bottom: 250px;
  position: relative; */
}
#section-hero .tab-panel-main .input-box {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #e2e8f0;
  background: var(--white-100, #fff);

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
#section-hero .tab-panel-main .input-box input {
  width: 100%;
}
#section-hero .message-box {
  color: white;
  display: flex;
  width: 360px;
  padding: 22px 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background: #111827;
  position: absolute;

  box-shadow: 0px 4px 6px -2px rgba(4, 9, 25, 0.05),
    0px 10px 15px -3px rgba(4, 9, 25, 0.1);
}
#section-hero .message-box.box-top-right {
  border-radius: 24px 24px 24px 0px;
  right: 0;
  top: 0;
  transform: translateX(10%);
}
#section-hero .message-box.box-bottom-left {
  border-radius: 24px 0px 24px 24px;
  bottom: 0;
  left: 0;
  transform: translateX(-10%);
}


.scrollButton {
  align-items: end;
  position: fixed;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.5s ease; 
}
.scrollText{
  /* opacity: 0; */
  transition: all 0.5s ease; 
  background: transparent !important;
}


/* ---------------------------------------------------- section-logocloud ---------------------------------------------------- */
#section-logocloud {
  padding-top: 48px;
  padding-bottom: 90px;
}
#section-logocloud p {
  color: #6b7280;
}
#section-logocloud .logo-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  justify-content: center;
}
#section-logocloud .logo-container .logo {
  height: 64px;
  padding: 6px 22px;
}

/* ---------------------------------------------------- section-our-solution ---------------------------------------------------- */

#section-our-solution {
  padding-bottom: 112px;
}
#section-our-solution .background-image {
  padding-top: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../assets/img/img-background-outsolution-section.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-carousel-container {
  width: calc(100% - 200px);
  position: static !important;
}
.card-item-container {
  width: 389px;
  height: 196px;
  padding: 0 12px;
  margin: 24px 0;
}
.card-item-content {
  height: 100%;
  width: 100%;
  padding: 24px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  background: var(--system-basic-white, #fff);
  cursor: pointer;

  box-shadow: 0px 4px 6px -2px rgba(4, 9, 25, 0.05),
    0px 10px 15px -3px rgba(4, 9, 25, 0.1);
}
.card-item-content .box-icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #f9fafb;
}
.card-item-content:hover .box-icon {
  background: #e6f5eb;
}
.card-item-content:hover .icon {
  color: #030712;
}
.card-item-content:hover p {
  color: #111827;
}
.card-item-content:hover .line {
  border-radius: 4px;
  background: #f3f4f6;
}
.card-item-content:hover .progress-line {
  border-radius: 4px;
  background: #030712;
  z-index: 10;
}
#section-our-solution .react-multiple-carousel__arrow {
  width: 30px;
  height: 30px;
  min-height: auto !important;
  min-width: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
#section-our-solution .react-multiple-carousel__arrow::before {
  font-size: 16px !important;
}
#section-our-solution .business-page-image {
  background-image: url("../../assets/img/img-background-outsolution-section-2.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: right top;

  border-radius: 16px;
  border: 4px solid #f3f4f6;

  box-shadow: 0px 10px 10px -5px rgba(4, 9, 25, 0.04),
    0px 12px 20px -4px rgba(4, 9, 25, 0.1);
}

/* ---------------------------------------------------- section-how-it-work-1 ---------------------------------------------------- */

#section-how-it-work-1 {
  background: #020617;
  background-image: url("../../assets/img/img-background-section-how-it-work1-1.png"),
    url("../../assets/img/img-background-section-how-it-work1-2.png");
  background-size: 80%, 50%;
  background-repeat: no-repeat, no-repeat;
  background-position: top center;
}
#section-how-it-work-1 .card-content {
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 0 0 0.2px #f1f2f4;
  display: flex;
  padding: 24px 32px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
}
#section-how-it-work-1 .card-background {
  cursor: pointer;
  height: 100%;
}

#section-how-it-work-1 .icon {
  color: #4B5563;
}
#section-how-it-work-1 .card-background:hover {
  border-radius: 12px;
  background-image: url("../../assets/img/img-background-section-how-it-work1-3.png"),
    url("../../assets/img/img-background-section-how-it-work1-4.png");
  background-size: 70%, 100%;
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom;
}
#section-how-it-work-1 .card-background:hover .card-content {
  box-shadow: 0 0 0 0.5px #e5e7eb;
}
#section-how-it-work-1 .card-background:hover .icon {
  color: #e6f5eb;
}
#section-how-it-work-1 .card-background:hover .step {
  color: #90dea9;
}
#section-how-it-work-1 .card-background:hover .text1 {
  color: #f9fafb;
}
#section-how-it-work-1 .card-background:hover .text2 {
  color: #9ca3af;
}

/* ---------------------------------------------------- section-how-it-work-2 ---------------------------------------------------- */
#section-how-it-work-2 {
  background-image: url("../../assets/img/img-background-section-how-it-work2-1.png");
  background-size: 25% 25%;
  background-repeat: no-repeat;
  background-position: left top;
}
#section-how-it-work-2 .card-content {
  border-radius: 16px;
  background: #f3f4f6;
  display: flex;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
#section-how-it-work-2 .badge {
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  font-size: 14px;
  width: fit-content;
  margin: 4px;
  font-weight: 400;
}
#section-how-it-work-2 .badge.white {
  background: white;
  color: #030712;
}
#section-how-it-work-2 .background-image {
  background: #020617;
  background-image: url("../../assets/img/img-background-section-how-it-work2-3.svg"),
    url("../../assets/img/img-background-section-how-it-work2-2.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, right top;
}

/* ---------------------------------------------------- section-testimonials ---------------------------------------------------- */
#section-testimonials {
  background: #020617;
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: space-between;
  background-image: url("../../assets/img/img-background-section-testimonials-1.png"),
    url("../../assets/img/img-background-section-testimonials-2.png");
  background-repeat: no-repeat, no-repeat;
  background-position: top center, left top;
  background-size: 100%, 40%;
  position: relative;
}
#section-testimonials .custom-carousel-container {
  width: 512px;
}
#section-testimonials .card-item-container {
  height: fit-content;
}
#section-testimonials .react-multiple-carousel__arrow {
  display: none;
}
#section-testimonials .btn-carousel {
  height: 36px;
  width: 36px;
  border-radius: 4px;
  background: #111827;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
#section-testimonials .btn-carousel.left {
  left: 50px;
}
#section-testimonials .btn-carousel.right {
  right: 50px;
}
#section-testimonials .card-content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid #fff;
  background: #fff;
  backdrop-filter: blur(8px);
}
#section-testimonials .text-content {
  width: calc(100% - 512px);
}
#section-testimonials .point {
  border-radius: 50%;
  background: #d1d5db;
  width: 7px;
  height: 7px;
  margin: 0 3px;
}

/* ---------------------------------------------------- section-things-to-love ---------------------------------------------------- */
#section-things-to-love {
  background-image: url("../../assets/img/img-background-section-things-to-love-1.png"),
    url("../../assets/img/img-background-section-things-to-love-3.png"),
    url("../../assets/img/img-background-section-things-to-love-4.png");
  background-repeat: no-repeat, repeat-y, no-repeat;
  background-position: top center,  top right, left center;
  background-size: 90%, 50%, 60%, 50%;
}
#section-things-to-love .card-content {
  border-radius: 16px;
  background: #f9fafb;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 6px -2px rgba(4, 9, 25, 0.05),
    0px 10px 15px -3px rgba(4, 9, 25, 0.1);
}
#section-things-to-love .card-content img {
  width: 100%;
}
#section-things-to-love .card-content ul {
  list-style: inherit;
  padding-left: 30px;
  color: #374151;
  font-size: 14px;
}
#section-things-to-love .card-content .try-now-link {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
#section-things-to-love .card-content .try-now-link:hover,
#section-things-to-love .card-content .try-now-link:hover .icon {
  color: #6bc78a;
}

/* ---------------------------------------------------- section-faq -------------------------------------------------- */
#section-faq {
  width: 100%;
  background: #020617;
  background-image: url("../../assets/img/img-background-section-faq-1.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 45%;
  display: flex;
  flex-direction: row;
  align-items: start;
}
#section-faq .background-image {
}
#section-faq .card-content {
  border-radius: 16px;
  border: 1px solid #111827;
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 20px; */
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
#section-faq .btn-collapse {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #111827;
  transition: all 0.3s ease-in-out;
}
#section-faq .card-content .txt-answer {
  display: none;
}
#section-faq .card-content.active .btn-collapse {
  background: #f3f4f6;
}
#section-faq .card-content.active .txt-answer {
  display: block;
}

/* ---------------------------------------------------- section-cta -------------------------------------------------- */
#section-cta {
  background-image: url("../../assets/img/ellipse-6.png"),
    url("../../assets/img/bg-grid.png"), url("../../assets/img/ellipse-5.png");
  background-repeat: no-repeat;
  background-position: top right, top center, left bottom;
  background-size: 40%, 100%, 40%;
}
#section-cta .panel {
  width: 100%;
  border: 1px solid #e5e7eb;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
#section-cta .panel-header {
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

#section-cta .panel-header .search-bar {
  width: 50%;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #f3f4f6;
  padding: 4px 0;
  margin: auto;
}
#section-cta .panel-main {
}
@media (max-width: 1023px) {
  header {
    padding: 16px 24px;
  }
  .tab-panel-main .search-content .input-box {
    width: 60%;
  }
  section {
    padding-left: 24px;
    padding-right: 24px;
  }
  #section-hero {
    padding-bottom: 30px;
  }
  #section-hero .tab-panel-container {
    padding: 24px;
  }
  #section-hero .message-box {
    width: 263px;
  }
  #section-hero .message-box.box-top-right {
    transform: translateX(6%);
  }
  #section-hero .message-box.box-bottom-left {
    transform: translateX(-6%);
  }

  #section-logocloud {
    padding-bottom: 0;
  }
  #section-our-solution {
    padding-right: 0;
    padding-left: 0;
  }
  #section-our-solution .background-image {
    padding-top: 128px;
  }
  #section-our-solution .custom-carousel-container {
    width: calc(100% - 24px);
  }
  #section-our-solution .react-multiple-carousel__arrow--left {
    left: 8px !important;
  }
  #section-our-solution .react-multiple-carousel__arrow--right {
    right: 8px !important;
  }
  #section-how-it-work-2 {
    background-size: 75% 15%;
  }
  #section-how-it-work-1 {
    background-size: 100%, 90%;
  }
  #section-testimonials {
    flex-direction: column;
  }
  #section-testimonials .custom-carousel-container {
    width: 100%;
  }
  #section-testimonials .text-content {
    width: 100%;
  }
  #section-faq {
    flex-direction: column;

    background-image: url("../../assets/img/img-background-section-faq-2.png"),
      url("../../assets/img/img-background-section-faq-3.png"),
      url("../../assets/img/img-background-section-faq-4.png");
    background-repeat: no-repeat;
    background-position: top center, top center, bottom left;
    background-size: 110%, 150%, 60%;
  }
  #section-testimonials .btn-carousel {
    position: static;
    top: 0;
    transform: translateY(0);
  }
}

@media (max-width: 767px) {
  #section-hero .message-box {
    width: 280px;
  }
  #section-hero .message-box.box-top-right {
    border-radius: 24px 0px 24px 24px;
    bottom: 0;
    left: 0;
    top: auto;
    right: auto;
    transform: translateX(300px);
  }
  #section-hero .message-box.box-bottom-left {
    transform: translateX(0);
  }
  .tab-panel-container {
    padding: 12px;
  }

  .btn-contact {
    width: 100%;
  }
  #section-how-it-work-2 {
    background-size: 100% 10%;
  }
}
